import * as React from "react"
import { Layout } from "./layout/layout"
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo"
import { AboutStatistics } from "../../AboutStatistics/aboutStatistics"
import { AboutCompany } from "../../AboutCompany/AboutCompany"
import { ProcessCardIcon } from "../../ProcessCard/processCardIcon"
import { Experts } from "../../Experts/experts"
import { OurProcess } from "../../../components/OurProcess/ourProcess"
import { Video } from "../../Video/video"
import { Reviews } from "../../Reviews/reviews"
import { TextCopies } from "../../../helpers/countryTextCopies"
import { AboutTextComponents } from "../../TextComponents/about"
import { returnLink } from "../../../helpers/returnLink"
import { CustomButton } from "../../common/CustomButton"
import AboutHeaderImg from "../../../images/about/aboutHeaderImg.webp"
import CircleLeft from "../../../assets/about/aboutCircleLeft.svg"
import CircleRight from "../../../images/about/aboutCircleRight.webp"
import HonestyIcon from "../../../assets/about/honestyIcon.svg"
import QualityIcon from "../../../assets/about/qualityIcon.svg"
import CareIcon from "../../../assets/about/careIcon.svg"
import TeamworkIcon from "../../../assets/about/teamworkIcon.svg"
import ResearchIcon from "../../../assets/about/researchAndDevelopmentIcon.svg"
import PropTypes from "prop-types"
import "../../../styles/about.scss"

const About = ({ prefix, treatmentType }) => {
  // ABOUTPAGE SCHEMA START //
  const schema = {
    "@context": "https://schema.org",
    "@type": "AboutPage",
    name: "Clinique de Santé Masculine authentique que tout le monde connaît et en qui on a confiance, à l'échelle mondiale.",
    description:
      "Établie en 2012, notre parcours a révolutionné la santé masculine en Australie. Clinique de Santé Masculine a été fondée localement à Sydney, en Australie, avec quelques membres d'équipe. Nous avons été l'une des premières cliniques en Australie à plaider ouvertement pour la santé sexuelle des hommes et à remettre en question les stigmates associés aux hommes cherchant de l'aide. Depuis lors, nos traitements et services se sont étendus à l'échelle mondiale, nous permettant d'intégrer et d'adapter de nouvelles technologies pour offrir une commodité optimale à tous nos patients.",
    url: `https://menshealthclinic.com/${prefix}/about`,
  }
  // ABOUTPAGE SCHEMA END //

  const processCards = [
    {
      titleText: "Qualité",
      text: AboutTextComponents.qualityText(prefix),
      img: QualityIcon,
    },
    {
      titleText: "Recherche et développement",
      text: AboutTextComponents.researchText(prefix),
      img: ResearchIcon,
    },
    {
      titleText: "Soins",
      text: AboutTextComponents.careText(prefix),
      img: CareIcon,
    },
    {
      titleText: "Travail d'équipe",
      text: AboutTextComponents.teamworkText(prefix),
      img: TeamworkIcon,
    },
    {
      titleText: "Honnêteté",
      text: AboutTextComponents.honestyText(prefix),
      img: HonestyIcon,
    },
  ]

  return (
    <Layout
      headerLink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "aboutmenuquiz",
        false,
        prefix
      )}
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "aboutbottomquiz",
        false,
        prefix
      )}
      prefix={prefix}
    >
      <GatsbySeo
        title="Clinique de Santé Masculine Canada | ED & PE Traitement"
        description="La Clinique de Santé Masculine aide les hommes à travers l'Australie avec la dysfonction érectile, l'éjaculation précoce et les douleurs chroniques. Lisez les avis et apprenez-en davantage sur nos traitements."
        languageAlternates={[
          {
            hrefLang: "x-default",
            href: "https://menshealthclinic.com/au/about/",
          },
          { hrefLang: "en-au", href: "https://menshealthclinic.com/au/about/" },
          { hrefLang: "en-ca", href: "https://menshealthclinic.com/ca/about/" },
          { hrefLang: "en-nz", href: "https://menshealthclinic.com/nz/about/" },
          { hrefLang: "en-ph", href: "https://menshealthclinic.com/ph/about/" },
          { hrefLang: "en-gb", href: "https://menshealthclinic.com/uk/about/" },
          {
            hrefLang: "fr-ca",
            href: "https://menshealthclinic.com/fr-ca/about/",
          },
        ]}
      />
      <JsonLd json={schema} />
      <article>
        <div className="about__header--wrapper relative mx-auto w-full px-100">
          <CircleLeft className="about__header--circle-left" />
          <img className="about__header--img-right" src={CircleRight} />
          <div className="mx-auto max680:max-w-316">
            <h1 className="about__header--title relative text-3xl font-bold text-white max480:text-base">
              La clinique authentique de santé masculine que tout le monde
              connaît et en qui on a confiance, à l'échelle mondiale.
            </h1>
            <p className="about__header--text relative px-4 max480:text-sm">
              Notre équipe est prête lorsque vous l'êtes ! Tout ce que vous avez
              à faire, c'est vous prioriser et entrer en contact avec nous.
            </p>
          </div>
          <div className="relative">
            <img
              className="about__header--img max800:hidden"
              src={AboutHeaderImg}
              alt="Planifiez un rendez-vous à Clinique de Santé Masculine."
            ></img>
            <CustomButton
              className="about__header--btn orangeFilled"
              text="Planifiez un rendez-vous"
              link={
                treatmentType === "Chronic Pain Treatments & Solutions"
                  ? "https://menshealthclinic.as.me/chronicpainwebsite"
                  : TextCopies.acuityLink(prefix)
              }
            />
          </div>
        </div>
        <AboutStatistics
          itemClass="1024:w-[50%] 1100:w-auto"
          className="width__container mb-115 max900:mb-50"
          prefix={prefix}
        />
      </article>

      <main>
        <AboutCompany prefix={prefix} />
        <section className="width__container mb-125 max680:mb-60">
          <div className="flex flex-wrap justify-between max1360:justify-center">
            <div className="about__process--title-box">
              <h4 className="dashed__text--blue treatments__blue--text blueText">
                Nos principes / valeurs
              </h4>
              <h2 className="section__title company__title">
                Nos valeurs sont en accord avec les vôtres.
              </h2>
            </div>
            {processCards.map((item, index) => (
              <ProcessCardIcon
                Component={item.img}
                titleText={item.titleText}
                text={item.text}
                key={index}
                className={index % 2 === 0 ? "1000:ml-5" : "1000:mr-5"}
              />
            ))}
          </div>

          <CustomButton
            className="orangeFilled slim__btn ml-[50%] mt-40 translate-x-[-50%] max680:mt-0"
            text="Évaluation en ligne"
            link={
              TextCopies.assessmentQuizLinks(prefix)
                ? TextCopies.assessmentQuizLinks(prefix)
                : returnLink(
                    "Website-Quiz",
                    "Supplementary-Quiz",
                    "aboutvaluesquiz",
                    false,
                    prefix
                  )
            }
          />
        </section>

        <Experts
          experts={TextCopies.experts(prefix)}
          slider={prefix == "ca" ? false : true}
          className="flex-wrap"
          prefix={prefix}
          title="Offrant un haut degré de qualité, d'excellence et de soin."
        />

        <OurProcess prefix={prefix} treatmentType={treatmentType} />

        <Video
          blueDashedText={"Rencontrez le Dr Fred Edwards"}
          prefix={prefix}
        />

        <Reviews
          className="mb-250 max1260:mb-145"
          link={
            TextCopies.assessmentQuizLinks(prefix)
              ? TextCopies.assessmentQuizLinks(prefix)
              : returnLink(
                  "Website-Quiz",
                  "Supplementary-Quiz",
                  "aboutreviewsquiz",
                  false,
                  prefix
                )
          }
          prefix={prefix}
        />
      </main>
    </Layout>
  )
}

export default About

About.propTypes = {
  prefix: PropTypes.string,
  treatmentType: PropTypes.string,
}
